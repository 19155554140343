import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useNavigate } from "react-router-dom";
import RowCard from "./RowCard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function Dashboard({ HostUrl }) {

    const [list, setList] = useState([])
    const [search, setSearch] = useState("")
    const [Loggedin, setLoggedin] = useState(false);
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const navigate = useNavigate();
    useEffect(() => {
       
        if( localStorage.getItem("loginStatus")=="true")
        {
            setLoggedin(true)
        }
      
        fetch(HostUrl + "getList.php", {
            method: "POST",


        })
            .then(res => res.json())
            .then(data => setList(data))

    })

    function handleSearch() {
        if (search == "") {
            toast.error("Search cannot be empty")
        }
        else {
            navigate(`/edit/${search}`)
        }

    }

    function handleLogin()
    {
        let formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);
       

        fetch(HostUrl + "adminLogin.php", {
            method: "POST",
            body: formData,
    })
            .then(res => res.json())
            .then(data => ValidateResponse(data))

       
    }

    function ValidateResponse(out)
    {
        console.log(out)
        if(out==true)
        {
            
            localStorage.setItem("loginStatus", true);
            setLoggedin(true)
        }

        else
        {
            toast.error("Invalid Login credentials")
        }
    }

    if (Loggedin == true) {
        return (

            <div className="bg flex flex-col p-5 w-full min-h-screen">
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <h1 className="text-slate-50 text-2xl m-5">Admin Dashboard</h1>
                <div className="flex flex-row w-full items-center justify-between pr-5"><h1 className="text-slate-50 text-xl m-5">Certificate List</h1>
                    <div><input type={"text"} placeholder="Certificate ID" className="h-10 rounded pl-2 w-72" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                        <button className="bg-purple-600 p-2 rounded ml-2 text-slate-50" onClick={() => { handleSearch() }}>Search</button>
                        <button className="bg-yellow-600 p-2 rounded ml-2 text-slate-50" onClick={() => { navigate("/add") }}>Add New</button>
                        <button className="bg-red-600 p-2 rounded ml-2 text-slate-50" onClick={() => { localStorage.setItem("loginStatus",false); setLoggedin(false)}}>Logout</button>
                    </div>

                </div>
                <div className="flex flex-row bg-gray-800 text-slate-50 justify-between items-center mx-5 mb-0  p-2 rounded  ">


                    <h1 className="font-bold w-20 font-bold">ID</h1>
                    <h1 className="w-72 font-bold">Student Name</h1>

                    <h1 className="w-20 font-bold">Video 1 ID</h1>
                    <h1 className="w-20 font-bold">Video 2 ID</h1>
                    <h1 className="w-20 font-bold">Actions</h1>

                </div>
                <div>
                    {list.map((item) => {
                        return (
                            <RowCard data={item} />
                        )
                    })}
                </div>


            </div>
        )
    }
    else {
        return (
            <h1><div className="bg flex flex-col p-5 w-full min-h-screen">
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="flex flex-col items-center justify-center">
            <h1 className="text-slate-50 text-2xl m-5">Admin Login</h1>
            <input type={"text"} placeholder="Username" className="rounded pl-3 mb-3" value={username} onChange={(e)=>{setUsername(e.target.value)}}/>
            <input type={"password"} placeholder="Password" className="rounded pl-3"  value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
            <button className="bg-green-600 m-2 px-3 rounded hover:bg-green-500 text-slate-50" onClick={()=>{handleLogin()}}>Login</button>
            </div>
            


        </div></h1>
        )
    }
}