import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Edit({ HostUrl }) {

    let params = useParams();
    let navigate = useNavigate()
    const [certId, setCertId] = useState()
    const [name, setName] = useState()
    const [vid1, setVid1] = useState()
    const [vid2, setVid2] = useState()

    useEffect(() => {
        if( localStorage.getItem("loginStatus")=="false" || localStorage.getItem("loginStatus")===null)
        {
            navigate("/")
        }
        let formData = new FormData();
        formData.append("certId", params.id)
        fetch(HostUrl + "getContent.php", {
            method: "POST",
            body: formData,

        })
            .then(res => res.json())
            .then(data => setData(data))



    }, [])

    function setData(data) {
        setCertId(data[0].certid)
        setName(data[0].stdname)
        setVid1(data[0].link1)
        setVid2(data[0].link2)
    }

    function handleSave() {
        let formData = new FormData();
        formData.append("certId", certId);
        formData.append("stdname", name);
        formData.append("vidid1", vid1);
        formData.append("vidid2", vid2);

        fetch(HostUrl + "save.php", {
            method: "POST",
            body: formData,
        })
            .then(res => res.json())
            .then(data => validateResponse(data,"edit"))

    }

    function handleDelete() {
        let formData = new FormData();
        formData.append("certId", certId);

        fetch(HostUrl + "delete.php", {
            method: "POST",
            body: formData,
        })
            .then(res => res.json())
            .then(data => validateResponse(data,"del"))
    }
    function validateResponse(data, type) {
        if (data == true && type=="edit") {
            toast.success("Your changes has been saved")
        }
        else if(data==true && type=="del")
        {
            toast.success("Item deleted")
            navigate("/dashboard")
            
        }
        else
        {
            toast.error("Oops! Something went wrong")
           
        }

    }
    return (
        <div className="flex flex-row bg h-screen p-3 w-screen items-center justify-center">
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="flex flex-col  h-full  justify-center">
            <button className="self-start rounded text-blue-500 mb-3 hover:text-blue-300" onClick={()=>{navigate("/dashboard")}}> Go Back</button>
                <h1 className="text-slate-50 text-2xl mb-5">Edit Data</h1>
                <p className="text-slate-50">Certificate ID:</p>
                <input type={"text"} className="w-96 rounded mb-5  p-2" value={certId} onChange={(e) => { setCertId(e.target.value) }} />

                <p className="text-slate-50">Name:</p>
                <input type={"text"} className="w-96 rounded mb-5 p-2" value={name} onChange={(e) => { setName(e.target.value) }} />


                <p className="text-slate-50">Video 2 ID:</p>
                <input type={"text"} className="w-96 rounded mb-5 p-2" value={vid1} onChange={(e) => { setVid1(e.target.value) }} />

                <p className="text-slate-50">Video 2 ID:</p>
                <input type={"text"} className="w-96 rounded mb-5 p-2" value={vid2} onChange={(e) => { setVid2(e.target.value) }} />

                <button className="bg-green-600 rounded text-slate-50 py-2 mb-2 hover:bg-green-500 active:bg-green-900" onClick={() => { handleSave() }}> Save</button>
                <button className="bg-red-600 rounded text-slate-50 py-2 hover:bg-red-500 active:bg-red-900" onClick={() => { handleDelete() }}> Delete</button>


            </div>

        </div>
    )
}